import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("albaranes"),
  transferToSage50(id) {
    return apiClient.post(`/albaranes/transfer-sage50`, { id: id });
  },
  getAgentsList(id = undefined) {
    let path = "/tecnicos/list";
    if (id) path += `?albaran_id=${id}`;
    return apiClient.get(path);
  },
  getArticles(id = undefined) {
    let path = `/articulos-familias/list`;
    if (id) path += `?albaran_id=${id}`;
    return apiClient.get(path);
  },
  getPaymentMethods() {
    return apiClient.get(`/payment-methods/items`);
  },
  setGarantia(id) {
    return apiClient.put(`/albaranes/${id}/garantia`);
  },
  unsetGarantia(id) {
    return apiClient.put(`/albaranes/${id}/revoke-garantia`);
  },
  getPdf(ids) {
    return apiClient.get(`/albaranes/${ids}/pdf`);
  },
  sendEmail(id) {
    return apiClient.post(`/albaranes/${id}/send-email`);
  },
  changeCustomer(id, customer_id) {
    let params = { cliente_id: customer_id };
    return apiClient.post(`/albaranes/${id}/cambio-cliente`, params);
  },
};
