import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("puertas"),
  createQuote(id) {
    return apiClient.post(`/puertas/${id}/crear-presupuesto`);
  },
  getCustomer(id) {
    return apiClient.get(`/puertas/${id}/info-cliente`);
  },
};
