import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/products",
  component: Intranet,
  redirect: { name: "productsCrud" },
  meta: {
    title: "Articles",
  },
  children: [
    {
      path: "list",
      name: "productsCrud",
      component: () => import("@/views/crud/ArticuloCrud.vue"),
      meta: {
        auth: true,
        serviceName: "articulos",
        title: "Llista d'articles",
      },
      children: [
        {
          path: ":id",
          name: "productsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "articulos",
            title: "Articles",
          },
        },
      ],
    },
    {
      path: "categories",
      name: "productCategoriesCrud",
      component: () => import("@/views/crud/ArticuloFamiliaCrud.vue"),
      meta: {
        auth: true,
        serviceName: "articulos-familias",
        title: "Famílies d'articles",
      },
      children: [
        {
          path: ":id",
          name: "productCategoriesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "articulos-familias",
            title: "Família d'articles",
          },
        },
      ],
    },
  ],
};
