import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("presupuestos"),
  getAgentsList(id = undefined) {
    let path = "/tecnicos/list";
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  getArticles(id = undefined) {
    let path = "/articulos-familias/list";
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  getPdf(ids) {
    return apiClient.get(`/presupuestos/${ids}/pdf`);
  },
  getQuoteAmount(id) {
    return apiClient.get(`/presupuestos/${id}/precio`);
  },
  duplicarPresupuesto(id) {
    return apiClient.post(`/presupuestos/${id}/duplicar`);
  },
  realizarAnticipo(id, params) {
    return apiClient.post(`/presupuestos/${id}/anticipo`, params);
  },
  aceptarPresupuesto(id, params) {
    return apiClient.post(`/presupuestos/${id}/aceptar`, params);
  },
  rechazarPresupuesto(id) {
    return apiClient.post(`/presupuestos/${id}/rechazar`);
  },
  setPresupuestoPendiente(id) {
    return apiClient.post(`/presupuestos/${id}/pendiente`);
  },
  sendEmail(id) {
    return apiClient.post(`/presupuestos/${id}/send-email`);
  },
  changeCustomer(id, customer_id) {
    let params = { cliente_id: customer_id };
    return apiClient.post(`/presupuestos/${id}/cambio-cliente`, params);
  },
  changeDoor(id, door_id, change_service) {
    let params = { puerta_id: door_id, cambiar_servicio: change_service };
    return apiClient.post(`/partes/${id}/cambio-puerta`, params);
  },
};
