import Cookies from "js-cookie";

export default {
  data() {
    return {
      $globalRules: {
        required: value => {
          if (Array.isArray(value) && value.length === 0) return false;
          return !(value === "" || value === null || value === undefined) || "Campo requerido";
        },
        number: value => {
          return value ? !isNaN(value) || "Nº." : true;
        },
        hour: value => {
          return value ? (value < 24 && value >= 0) || "24 h." : true;
        },
        minute: value => {
          return value ? (value < 60 && value >= 0) || "24 h." : true;
        },
        // Dates
        notPastDate: value => {
          const date = this.$moment(value, ["DD/MM/YYYY", "YYYY-MM-DD"]);
          const notPast = this.$moment(date).isAfter(this.$moment().subtract(1, "days"));
          return notPast || "Fecha pasada";
        },
      },
    };
  },
  methods: {
    $notifySuccess(title = "Correcte!", text = "Operació realitzada amb èxit.") {
      this.$notify({
        type: "success",
        title: title,
        text: text,
      });
    },
    $notifyError(
      title = "Error",
      text = "Hi ha hagut algun problema en el procés, prova-ho de nou més tard o contacta amb l'administrador."
    ) {
      this.$notify({
        type: "error",
        title: title,
        text: text,
      });
    },
    $formatNumberToCurrency(value) {
      // Ex:  get 2.5
      // console.log("NTC: getNumber", value)
      //const newValue = value.toLocaleString("es-ES")
      if (!value) return "0";

      return new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 4,
      }).format(value);

      // Ex: return 2,5
    },
    $formatCurrencyToNumber(value) {
      // Ex:  get 2,50
      //console.log("CTN: getCurrency", value);
      if (!value) return 0;
      if (typeof value === "number") {
        return value;
      }

      const splitValue = value.split(",");
      const units = splitValue[0].replace(".", "");
      const decimals = splitValue[1] || 0;
      return parseFloat(units + "." + decimals);

      // Ex:  return 2.5
    },
    $downloadPdf(service) {
      service
        .then(response => {
          const link = document.createElement("a");
          link.href = "data:application/octet-stream;base64," + response.data.binary;
          link.download = response.data.filename;
          link.dispatchEvent(new MouseEvent("click"));
          link.remove();
        })
        .catch(() => {
          this.$notifyError(undefined, "Error en la descàrrega del PDF.");
        });
      // PlanificacioService.getPdf(id).then(response => {
      //   const link = document.createElement("a")
      //   link.href =
      //     "data:application/octet-stream;base64," + response.data.binary
      //   link.download = response.data.filename + ".pdf"
      //   link.dispatchEvent(new MouseEvent("click"))
      // })
    },
    $getCookie(name) {
      return Cookies.get(name);
    },
  },
};
