import Vue from "vue";
import Vuex from "vuex";
import AuthService from "@/services/AuthService";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import router from "@/router";
import apiClient from "@/services/ApiClient";
import blobApiClient from "@/services/BlobApiClient";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

//Sotre Modules
import CancelRequests from "@/store/modules/CancelRequests";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    currentService: null,
    crudMetaData: {
      childEditCustomFunction: null, //{tabName: customFunctionNAme}
      customFunctions: null,
      downloadPDF: null,
      modelKeyTitle: "nombre",
      ssr: false,
    },
    login_redirect: null,
    userSettings: {
      darkMode: false,
      fontSize: 1,
    },
    user: {},
  };
};

export default new Vuex.Store({
  modules: {
    CancelRequests,
  },
  plugins: [
    createPersistedState({
      key: "state",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: getDefaultState(),
  mutations: {
    TOGGLE_DARK_MODE(state, event) {
      state.userSettings.darkMode = event;
    },
    UPDATE_FONT_SIZE(state, event) {
      state.userSettings.fontsize = event;
    },
    SET_USER_DATA(state, userData) {
      const { token } = userData;
      delete userData.token;

      const unix_timestamp = parseInt(userData.expiry);
      const expiryDate = new Date(unix_timestamp * 1000);
      const dateNow = new Date();
      const timeToExpiry = expiryDate - dateNow;
      const daysToExpiry = Math.round((timeToExpiry / (1000 * 60 * 60) / 24) * 2) / 2;

      Cookies.set("auth_token", token, {
        expires: daysToExpiry,
        sameSite: "Lax",
        secure: true,
      });

      apiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;
      blobApiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;

      state.user = userData;
      createPersistedState();
    },
    UPDATE_CURRENT_SERVICE(state, newService) {
      state.currentService = newService;
    },
    UPDATE_CRUD_METADATA(state, newData) {
      state.crudMetaData = newData;
    },
    CLEAR_USER_DATA(state) {
      Cookies.remove("auth_token");
      Object.assign(state, getDefaultState());
      createPersistedState();
    },
  },
  getters: {
    loggedIn: state => !!state.token,
  },
  actions: {
    toggleDarkMode({ commit }, event) {
      commit("TOGGLE_DARK_MODE", event);
    },
    updateFontSize({ commit }, event) {
      commit("UPDATE_FONT_SIZE", event);
    },
    updateCurrentService({ commit }, event) {
      commit("UPDATE_CURRENT_SERVICE", event);
    },
    updateCrudMetaData({ commit }, event) {
      commit("UPDATE_CRUD_METADATA", event);
    },
    login({ commit }, credentials) {
      return AuthService.login(credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
        const { login_redirect } = this.state;
        this.state.login_redirect = null;
        return login_redirect || router.resolve({ name: "dashboard" }).route.fullPath;
      });
    },
    logout({ commit }, event) {
      commit("CLEAR_USER_DATA", event);
      location.reload();
    },
  },
});
