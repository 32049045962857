import axios from "axios";

const axiosConfig = {
  baseURL: "/api",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export default axios.create({ ...axiosConfig, responseType: "blob" });