import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/settings",
  component: Intranet,
  redirect: { name: "workOrderTypes" },
  meta: {
    title: "Configuracions",
  },
  children: [
    {
      name: "workOrderTypesCrud",
      path: "work-orders-types",
      component: () => import("@/views/crud/TipoParteCrud.vue"),
      meta: {
        auth: true,
        serviceName: "tipos-partes",
        title: "Tipus de partes",
      },
      children: [
        {
          path: ":id",
          name: "workOrderTypesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "tipos-partes",
            title: "Tipus partes",
          },
        },
      ],
    },
    {
      name: "productTypesCrud",
      path: "product-types",
      component: () => import("@/views/crud/TipoArticuloCrud"),
      meta: {
        auth: true,
        serviceName: "tipos-articulos",
        title: "Tipus d'articles",
      },
      children: [
        {
          path: ":id",
          name: "productTypesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "tipos-articulos",
            title: "Tipus d'article",
          },
        },
      ],
    },
    {
      path: "maintenance-types",
      name: "maintenanceTypesCrud",
      component: () => import("@/views/crud/TipoMantenimientoCrud.vue"),
      meta: {
        auth: true,
        serviceName: "tipos-mantenimientos",
        title: "Tipus de manteniments",
      },
      children: [
        {
          path: ":id",
          name: "maintenanceTypesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "tipos-mantenimientos",
            title: "Tipus de manteniment",
          },
        },
      ],
    },
    {
      name: "doorTypesCrud",
      path: "doors-types",
      component: () => import("@/views/crud/TipoPuertaCrud.vue"),
      meta: {
        auth: true,
        serviceName: "tipos-puertas",
        title: "Tipus de portes",
      },
      children: [
        {
          path: ":id",
          name: "doorTypesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "tipos-puertas",
            title: "Tipus de porte",
          },
        },
      ],
    },
    {
      name: "serviceTypesCrud",
      path: "service-types",
      component: () => import("@/views/crud/TipoServicioCrud.vue"),
      meta: {
        auth: true,
        serviceName: "tipos-servicios",
        title: "Tipus de serveis",
      },
      children: [
        {
          path: ":id",
          name: "serviceTypesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "tipos-servicios",
            title: "Tipus de servei",
          },
        },
      ],
    },
    {
      path: "payment-methods",
      name: "paymentmethodsCrud",
      component: () => import("@/views/crud/PaymentMethodsCrud.vue"),
      meta: {
        auth: true,
        serviceName: "payment-methods",
        title: "Mètodes de pagament",
      },
      children: [
        {
          path: ":id",
          name: "paymentmethodsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "payment-methods",
            title: "Mètode de pagament",
          },
        },
      ],
    },
    {
      path: "users",
      name: "usersCrud",
      component: () => import("@/views/crud/UserCrud.vue"),
      meta: {
        auth: true,
        serviceName: "users",
        title: "Usuaris",
      },
      children: [
        {
          path: ":id",
          name: "usersCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "users",
            title: "Usuari",
          },
        },
      ],
    },
  ],
};
